import '../scss/main.scss';
import 'bootstrap';

function createMessage({status, message}) {
  const formMessage = document.querySelector('#form-message');

  const messageDiv = document.createElement('div');
  messageDiv.classList.add('t-message', `t-message-${status.toLowerCase()}`);
  messageDiv.innerHTML = `
    <div class="t-message-content">
      <div class="t-message-icon"></div>
      <div class="t-message-inner">
        <span class="t-message-inner-status">${status}</span>
        <span class="t-message-inner-text">${message}</span>
      </div>
    </div>
    <i class="fa-solid fa-xmark t-message-close-btn"></i>
  `;

  const closeBtn = messageDiv.querySelector('.t-message-close-btn');

  closeBtn.addEventListener('click', () => {
    messageDiv.remove();
  });

  formMessage.innerHTML = '';
  formMessage.appendChild(messageDiv);
}

(function() {
  function onSubmit(event) {
    event.preventDefault();

    const emailAddressInput = this.querySelector('input[name="email"]');
    const zipInput = this.querySelector('input[name="zip"]');
    const phoneInput = this.querySelector('input[name="phone"]');
    const submitButton = this.querySelector('#submit');

    submitButton.innerHTML = '<i class="fa-solid fa-spinner-third fa-spin"></i> Submitting...';
    
    const email = emailAddressInput.value.trim();
    const zip = zipInput.value.trim();
    const phone = phoneInput.value.trim();

    fetch('/api/team-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "email": email, "zip": zip, "phone": phone })
    })
    .then(response => {
      if (response.ok) 
        return response.json();
      throw response
    })
    .then(data => {
      emailAddressInput.value = '';
      zipInput.value = '';
      phoneInput.value = '';
      submitButton.innerHTML = 'JOIN TEAM TANNYA';
      createMessage(data);
    })
    .catch(error => {
      submitButton.innerHTML = 'JOIN TEAM TANNYA';
      error.json()
      .then(data => {
        createMessage(data);
      })
      .catch(() => {
        createMessage({status: 'Error', message: 'An unexpected error occurred. Please try again later.'});
      })
    });
  }

  document.querySelector('#team-form').addEventListener('submit', onSubmit);
})();